// src/components/Navbar.js
import React from 'react';
import bullyLogo from '../assets/images/bully-nav-logo.png'; // Path to your logo image

const Navbar = () => {
  return (
    <nav className="fixed top-0 left-0 w-full bg-kaspa shadow-md z-10">
      <div className="mx-auto flex justify-between items-center pt-5 pb-2 px-12">
        {/* Logo */}
        <a href="/" className="flex items-center space-x-2">
          <img src={bullyLogo} alt="Logo" className="h-12 w-30" /> {/* Adjust the height and width as needed */}
        </a>
      </div>
      <hr className="bg-amber-600 h-[2px] border-0"></hr>
    </nav>
  );
};

export default Navbar;
