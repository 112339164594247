import React from 'react';
import { motion } from 'framer-motion'; 
import bully3 from './../assets/images/bully_3.jpg'
import bully4 from './../assets/images/bully_4.jpg'
import bully5 from './../assets/images/bully_5.jpg'
import bully7 from './../assets/images/bully_7.jpg'
import bully8 from './../assets/images/bully_8.jpg'
// import kaspa from './../assets/images/socials/kaspa-2.png';
// import telegram from './../assets/images/socials/telegram-2.png';
// import xSocials from './../assets/images/socials/x.png';

const Gallery = () => {
  return (
    <motion.section
      className="bg-zinc-900 px-6 pt-20 pb-10 flex flex-col items-center justify-center"
      initial={{ opacity: 0, x: 0 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.7 }}
    >
      <h2 className="text-4xl font-bold text-center mb-12 text-amber-600">BE A BULLY!</h2>
      {/* Collage of Images */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-2">
        
        <div className="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-110 hover:shadow-xl transition-all duration-300">
          <img src={bully7} alt="Bully" className="w-full h-full object-cover" />
        </div>

        <div className="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-110 hover:shadow-xl transition-all duration-300">
          <img src={bully8} alt="Bully" className="w-full h-full object-cover" />
        </div>
        
        <div className="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-110 hover:shadow-xl transition-all duration-300">
          <img src={bully3} alt="Bully" className="w-full h-full object-cover" />
        </div>
        
        <div className="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-110 hover:shadow-xl transition-all duration-300">
          <img src={bully4} alt="Bully" className="w-full h-full object-cover" />
        </div>
        
        <div className="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-110 hover:shadow-xl transition-all duration-300">
          <img src={bully5} alt="Bully" className="w-full h-full object-cover" />
        </div>
      </div>
      <br/><br/>
      {/* Social Icons */}
      {/* <br/><br/><br/>
      <div className="flex space-x-6 items-center justify-center">
        <a href="https://x.com/Bullykas" target="_blank" rel="noreferrer" className="transform hover:scale-110 hover:shadow-xl transition-all">
          <img src={xSocials} alt="X (Twitter)" className="w-12 h-12 md:w-16 md:h-16 object-contain cursor-pointer" />
        </a>
        <a href="https://t.me/bully_kas" target="_blank" rel="noreferrer" className="transform hover:scale-110 hover:shadow-xl transition-all">
          <img src={telegram} alt="Telegram" className="w-12 h-12 md:w-16 md:h-16 object-contain cursor-pointer" />
        </a>
        <a href="https://kas.fyi/token/krc20/BULLY" target="_blank" rel="noreferrer" className="transform hover:scale-110 hover:shadow-xl transition-all">
          <img src={kaspa} alt="Kaspa" className="w-12 h-12 md:w-16 md:h-16 object-contain cursor-pointer" />
        </a>
      </div> */}
    </motion.section>
  );
};

export default Gallery;
