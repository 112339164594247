// src/App.js
import React, { useRef } from 'react';
import Navbar from './components/Navbar';
import SectionOne from './components/SectionOne';
import Exchanges from './components/Exchanges';
import HowToBuy from './components/HowToBuy';
import Tokenomics from './components/Tokenomics';
import RoadMap from './components/RoadMap';
import Gallery from './components/Gallery';
import Socials from './components/Socials';
// import TokenData from './components/TokenData';
import Donation from './components/Donation';
import Marquee from './components/MarqueeBully';
import { useInView } from './hooks/useInView';

function App() {
  const sectionOneRef = useRef(null);
  const isSectionOneInView = useInView(sectionOneRef);

  return (
    <div className="scroll-smooth">
      {!isSectionOneInView && <Navbar />}
      <SectionOne ref={sectionOneRef} />
      <Marquee /> {/* Add Marquee component here */}
      <Exchanges />
      <HowToBuy/>
      <Tokenomics/>
      <RoadMap/>
      <Gallery />
      <Socials />
      {/* <TokenData /> */}
      <Donation />
    </div>
  );
}

export default App;
