import React from 'react';
import biconomy from '../assets/images/biconomy.png';
import { motion } from 'framer-motion';

const Exchanges = () => {
  return (
    <motion.section
      id="exchanges"
      className="flex flex-col py-20 items-center justify-center bg-zinc-900 text-white font-handwriting"
      initial={{ opacity: 0, x: 0 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1 className="text-6xl font-bold mb-8 text-amber-600 text-center">EXCHANGES</h1>
      <h2 className="text-2xl text-center">Bully is available for trading on major exchanges.</h2>

      <br/><br/><br/><br/><br/>

      {/* Card Container */}
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 w-3/5 container"> */}
      <div className="flext items-center justify-center">
        
        {/* First Card */}
        <div className="bg-black rounded-lg overflow-hidden shadow-lg transform hover:scale-105 hover:shadow-xl transition-all duration-300 max-w-sm mx-4">
          <a href="https://www.biconomy.com/en/sign-up?r_user_id=W9R4F90RL" target="_blank" rel="noreferrer">
            <img src={biconomy} alt="Exchange 1" className="w-full object-cover" />
          </a>
        </div>

      </div>
        
      <br/><br/><br/><br/><br/>

      <div className="text-xl"> 
        More exchange listings are on the way.
      </div>
    </motion.section>
  );
};

export default Exchanges;
