import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useDonationBalance from '../hooks/useDonationBalance'; // Import your custom hook
import handHeart from './../assets/images/hand-heart.png';

const DonationSection = ({ donationGoal = 25000 }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const { bullyBalance, kaspaBalance, usdBalance, totalBalance, progress, loading } = useDonationBalance(donationGoal);

  const toggleMinimize = () => setIsMinimized((prev) => !prev);

  return (
    <motion.section
      className={`fixed bottom-4 right-4 md:bottom-8 md:right-8 bg-amber-600 text-white font-handwriting rounded-xl shadow-lg transition-all duration-300 ${
        isMinimized ? 'w-14 h-14 md:w-16 md:h-16 flex items-center justify-center' : 'w-80 md:w-1/3 lg:w-1/4 p-6'
      }`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      <div className={`${isMinimized ? 'hidden' : 'block'}`}>
        {/* Loading State */}
        {loading ? (
          <div className="text-xl text-center text-gray-700">
            Loading donation information...
          </div>
        ) : (
          // Donation Progress and Info
          <div className="text-gray-700">
            <h2 className="text-2xl font-bold text-white mb-4 text-center">Donation Progress</h2>

            {/* Progress Bar */}
            <div className="w-full bg-gray-300 rounded-full mb-4">
              <div
                className="bg-green-500 text-xs font-medium text-center text-white p-1 leading-none rounded-full"
                style={{ width: `${progress}%` }}
              >
                {progress.toFixed(1)}%
              </div>
            </div>

            {/* Donation Information */}
            <div className="text-md font-semibold space-y-3">
              <div>
                <span className="font-semibold">GOAL:</span>
                <span className="ml-2">${donationGoal.toLocaleString()}</span>
              </div>
              <div>
                <span className="font-semibold">BULLY:</span>
                <span className="ml-2">{bullyBalance.balance.toLocaleString()} BULLY (${bullyBalance.balanceInUSD.toLocaleString()})</span>
                <br/>
                <span className="font-semibold">KASPA:</span>
                <span className="ml-2">{kaspaBalance.balance.toLocaleString()} KASPA (${kaspaBalance.balanceInUSD.toLocaleString()})</span>
                <br/>
                <span className="font-semibold">USDT:</span>
                <span className="ml-2">${usdBalance.balance.toLocaleString()}</span>
              </div>
              <div>
                <span className="font-semibold">TOTAL:</span>
                <span className="ml-2">${totalBalance.toLocaleString()}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Minimize Button */}
      <button
        onClick={toggleMinimize}
        className="absolute bottom-2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition-all"
        title={isMinimized ? 'Expand' : 'Minimize'}
      >
        <img src={handHeart} alt="Toggle View" className="w-6 md:w-8" />
      </button>
    </motion.section>
  );
};

export default DonationSection;
