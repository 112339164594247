import { useState, useEffect, useMemo } from 'react';

const kaspaAddress = 'kaspa:qpfhggqth50rgma8z82ah5xeel20d9dkexqjlthfus6u77fag55pk78cg6le6'; // Example Kaspa address
const tronAddress = 'TW5so8pEowkx7WSwK68SV9Fo7HXfK8afys'
const tokenSymbol = 'BULLY'; // Token symbol

const addDecimal = (value) => {
  return value.toString().slice(0, -8) + '.' + value.toString().slice(-8);
}

const useDonationBalance = (donationGoal) => {
  const [bullyBalance, setBullyBalance] = useState({
    floorPrice: 0,
    balance: 0,
  });
  const [kaspaBalance, setKaspaBalance] = useState({
    floorPrice: 0,
    balance: 0,
  });
  const [usdBalance, setUsdBalance] = useState({
    balance: 0,
  });
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          bullyBalanceResponse,
          bullyFloorPriceResponse,
          kaspaBalanceResponse,
          kaspaFloorPriceResponse,
          tronDataResponse,
          trxToUsdResponse
        ] = await Promise.all([
          fetch(`https://api.kasplex.org/v1/krc20/address/${kaspaAddress}/token/${tokenSymbol}`),
          fetch(`https://api-v2-do.kas.fyi/token/krc20/BULLY/info?includeCharts=true&interval=7d`),
          fetch(`https://api.kaspa.org/addresses/${kaspaAddress}/balance`),
          fetch(`https://api.kaspa.org/info/price?stringOnly=false`),
          fetch(`https://api.trongrid.io/v1/accounts/${tronAddress}`),
          fetch('https://api.coingecko.com/api/v3/simple/price?ids=tron&vs_currencies=usd')
        ]);

        const bullyBalanceData = await bullyBalanceResponse.json();
        const bullyFloorPriceData = await bullyFloorPriceResponse.json();
        const kaspaBalanceData = await kaspaBalanceResponse.json();
        const kaspaFloorPriceData = await kaspaFloorPriceResponse.json();
        const tronData = await tronDataResponse.json();
        const trxToUsdData = await trxToUsdResponse.json();
        
        const balanceInSun = tronData.data[0]?.balance || 0
        const balanceInTRX = balanceInSun / 1000000
        const trxToUSD = trxToUsdData.tron.usd;
        const balanceInUSD = balanceInTRX * trxToUSD;
        
        setBullyBalance({
          balance: parseFloat(addDecimal(bullyBalanceData.result[0]?.balance) || 0),
          floorPrice: parseFloat(bullyFloorPriceData.price?.floorPrice || 0),
        });

        setKaspaBalance({
          balance: parseFloat(addDecimal(kaspaBalanceData?.balance) || 0),
          floorPrice: parseFloat(kaspaFloorPriceData?.price || 0),
        });

        setUsdBalance({
          balance: parseFloat(balanceInUSD)
        });
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching donation data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Fetch data only once on mount

  const totalBalance = useMemo(() => {
    const bullyBalanceInKaspa = bullyBalance.balance * bullyBalance.floorPrice;
    const bullyBalanceInUSD = bullyBalanceInKaspa * kaspaBalance.floorPrice;
    const kaspaBalanceInUSD = kaspaBalance.balance * kaspaBalance.floorPrice;

    return {
      bullyBalanceInKaspa,
      bullyBalanceInUSD,
      kaspaBalanceInUSD,
      total: bullyBalanceInUSD + kaspaBalanceInUSD + usdBalance.balance,
    };
  }, [bullyBalance, kaspaBalance, usdBalance.balance]);

  useEffect(() => {
    if (donationGoal > 0) {
      setProgress((totalBalance.total / donationGoal) * 100);
    }
  }, [totalBalance.total, donationGoal]);

  return {
    bullyBalance: {
      ...bullyBalance,
      balanceInKaspa: totalBalance.bullyBalanceInKaspa,
      balanceInUSD: totalBalance.bullyBalanceInUSD,
    },
    kaspaBalance: {
      ...kaspaBalance,
      balanceInUSD: totalBalance.kaspaBalanceInUSD,
    },
    usdBalance,
    totalBalance: totalBalance.total,
    progress,
    loading,
  };
};

export default useDonationBalance;
