// src/components/Marquee.js
import React from 'react';

const Marquee = () => {
  return (
    <div className="marquee-container overflow-hidden whitespace-nowrap bg-orange-200">
        
        <hr className="bg-amber-600 h-[2px] border-0"></hr>
        <div className="marquee-content flex animate-marquee space-x-8">
            {/* Apply the handwriting font style */}
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
            <span className="text-2xl font-semibold tracking-wide text-black-400 font-handwriting">
            Bully the Kaspa Bull!
            </span>
        </div>
        <hr className="bg-amber-600 h-[2px] border-0"></hr>
    </div>
  );
};

export default Marquee;
