import React from 'react';
import { motion } from 'framer-motion';
import kaspa from './../assets/images/socials/kaspa-2.png';
import telegram from './../assets/images/socials/telegram-2.png';
import xSocials from './../assets/images/socials/x.png';
import seriousBully from './../assets/images/serious-bully.png';
import bully3 from './../assets/images/bullyBG.jpg';

const Socials = () => {
  return (
    <motion.section
      className="relative py-12 bg-gradient-to-r from-[#6fc7ba] via-[#4ca99c] to-[#34817d] text-white font-handwriting overflow-hidden"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      {/* Decorative Background */}
      <div className="absolute inset-0 opacity-10 pointer-events-none">
        <img
          src={bully3}
          alt="Background Logo"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Social Icons Container */}
      <div className="relative z-2 max-w-4xl mx-auto flex flex-col items-center">
        {/* Section Title */}
        <h1 className="text-5xl font-bold mb-6 text-center text-shadow">
          CONNECT WITH US
        </h1>
        <p className="text-lg mb-10 text-center">
          Stay updated and join our community on these platforms.
        </p>

        {/* Icons */}
        <div className="flex space-x-8 items-center justify-center mb-12">
          {[
            { link: 'https://x.com/Bullykas', img: xSocials, alt: 'X (Twitter)' },
            { link: 'https://t.me/bully_kas', img: telegram, alt: 'Telegram' },
            { link: 'https://kas.fyi/token/krc20/BULLY', img: kaspa, alt: 'Kaspa' },
          ].map((social, index) => (
            <a
              key={index}
              href={social.link}
              target="_blank"
              rel="noreferrer"
              className="group transform hover:scale-110 transition-all duration-300"
            >
              <motion.img
                src={social.img}
                alt={social.alt}
                className="w-14 h-14 md:w-16 md:h-16 object-contain shadow-md group-hover:shadow-lg rounded-full bg-white p-2"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              />
            </a>
          ))}
        </div>

        {/* Central Image */}
        <motion.div
          className="relative w-60 h-60 md:w-80 md:h-80 rounded-full overflow-hidden shadow-lg transform hover:scale-105 transition-all duration-300"
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.6 }}
        >
          <img
            src={seriousBully}
            alt="Serious Bully"
            className="object-cover w-full h-full"
          />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Socials;
